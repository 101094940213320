import {HttpClient}  from '@angular/common/http';
import {Injectable}  from '@angular/core';
import {StorageService} from '@src/app/core/services/storage.service';
import {BaseService} from '@src/app/shared/services/base.service';
import {Observable}  from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseService
{
    constructor(
        public storageService: StorageService,
        private http: HttpClient
    )
    {
        super();
    }

    get user(): any
    {
        return JSON.parse(this.storageService.read('user')) ?? null;
    }

    set user(user: any)
    {
        this.storageService.write('user', JSON.stringify(user));
    }

    public list(pageNumber: number, pageSize: number = 50, array = null): Observable<any>
    {
        const url: string = array
            ? `${this.apiUrl}/users?array`
            : `${this.apiUrl}/users?page[number]=${pageNumber}&page[size]=${pageSize}`;

        return this.http.get(url).pipe();
    }

    public invite(data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/users/invite`, data).pipe();
    }

    public get(id): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/users/${id}`).pipe();
    }

    public update(data, id): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/users/${id}`, data).pipe();
    }

    public delete(id): Observable<any>
    {
        return this.http.delete(`${this.apiUrl}/users/${id}`).pipe();
    }

    public resendUserInvite(data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/users/invite/resend`, data).pipe();
    }

    public ErrorHandler(response): void
    {
        console.error(response);
    }
}
