
<div class="p-4 pt-0 align-self-center min-login-size" style="position: absolute;">
<div class="login-logo"></div>
<mat-card>
    <mat-card-header>
        <mat-card-title class="mt-2">Login</mat-card-title>
        <mat-card-subtitle>Sign in to your account.</mat-card-subtitle>


    </mat-card-header>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-card-content class="mt-2">
            <mat-form-field>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" matInput
                    placeholder="Email" formControlName="email" autocomplete="email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                        Email is required.
                    </div>

                    <div *ngIf="f.email.errors.email">
                        Please enter a valid email address.
                    </div>
                </div>
            </mat-form-field>

            <mat-form-field>
                <input type="password" matInput [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    placeholder="Password" formControlName="password" autocomplete="current-password">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                        Password is required.
                    </div>
                </div>
            </mat-form-field>

        </mat-card-content>

        <mat-card-actions class="m-0">
            <button type="submit" mat-flat-button color="primary">Login</button>
            <a mat-button color="primary" routerLink="/forgot-password">Forgot password?</a>
            <a mat-flat-button color="primary" style="float: right;" routerLink="/sign-up">Sign-up</a>
        </mat-card-actions>
    </form>
</mat-card>
</div>