import {Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseCreditsComponent } from '@src/app/admin/settings/components/purchase-credits/purchase-credits.component';
import { AuthService } from '@src/app/core/services/auth.service';
import { GlobalService } from '@src/app/core/services/global.service';
import {Breadcrumb}       from '@src/app/shared/models/breadcrumb.model';
import {Button}           from '@src/app/shared/models/button.model';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent
{
    @Input() public title: string;
    @Input() public breadcrumbs: Breadcrumb[];
    @Input() public buttons: Button[];
    @Input() public siteStatuses: any[];
    public activeSelection: any[] = [];

    public constructor(
        public globalService: GlobalService,
        public dialog: MatDialog,
        public authService: AuthService
    )
    {

    }

    public purchaseCredits(){
        const purchaseCreditModal = this.dialog.open(PurchaseCreditsComponent, {
            width: '60%',
            disableClose: true,
            data: null,
        });
    }

    public callback(button: Button, optionalValue?: any)
    {
        optionalValue ? button.callback(optionalValue) : button.callback();
    }

    public checkIfDisabled(id){

        if(this.globalService?.site?.status?.id == 7 || this.globalService?.site?.status?.id == 8){
            if([1, 5].includes(id)){
                return false;
            }
        }

        if(this.globalService?.site?.status?.id == 6 && id == 5){
            return false;
        }

        if(this.globalService?.site?.status?.id >= id){
            return true;
        }

        if(this.globalService?.site?.status?.id == 1 && id != 2){
            return true;
        }

        if(this.globalService?.site?.status?.id == 2 && id != 6){
            return false;
        }

        if(this.globalService?.site?.status?.id != 5 && id == 6) {
            return true;
        }
    }

    public getSiteStatusByPhase(id?: number){
        if(id){
            return this.siteStatuses.filter(c => c.phase?.id == id);
        } else {
            return this.siteStatuses.filter(c => c.phase == null);
        }
    }
}
