import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@src/app/core/services/auth.service';
import { PaymentService } from '@src/app/core/services/payment.service';

@Component({
	selector: 'app-purchase-credits',
	templateUrl: './purchase-credits.component.html',
	styleUrls: ['./purchase-credits.component.scss']
})
export class PurchaseCreditsComponent implements OnInit {

	public bundleForm = new UntypedFormGroup({
		price_id: new UntypedFormControl(null, Validators.required),
	});

	public products = [];
	public selectedProduct: any = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public purchaseCreditModal: MatDialogRef<PurchaseCreditsComponent>,
		public paymentService: PaymentService,
		public authService: AuthService,
		public activatedRoute: ActivatedRoute
	) { }

	ngOnInit() {
		this.paymentService.getProducts().subscribe((result: any) => {
			this.products = result.sort((a, b) => {
				return a.prices[0].unit_amount - b.prices[0].unit_amount;
			});
		});
	}

	public selectPackage(product) {
		console.log(product);
		this.selectedProduct = product;
		this.bundleForm.controls.price_id.setValue(product.default_price)
	}

	public closeDialog(response = null): void {
		this.purchaseCreditModal.close(response);
	}

	public checkout() {
		// Check the server.js tab to see an example implementation
		if (this.bundleForm.valid) {
			this.paymentService.checkout({
				"price_id": this.bundleForm.controls.price_id.value,
				"success_url": `${window.location.href}?session_id={CHECKOUT_SESSION_ID}`,
				"cancel_url": `${window.location.href}?payment_cancelled=true`
			}).subscribe((result: any) => {
				// If `redirectToCheckout` fails due to a browser or network
				// error, you should display the localized error message to your
				// customer using `error.message`.
				window.location.href = result.url;
				if (result.error) {
					alert(result.error.message);
				}
			});
		}
	}

}