import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MatDialog}                                   from '@angular/material/dialog';
import {MatPaginator, PageEvent}                     from '@angular/material/paginator';
import {MatTableDataSource}                          from '@angular/material/table';
import {Router}                                      from '@angular/router';
import {GlobalService}                               from '@src/app/core/services/global.service';
import {Breadcrumb}                                  from '@src/app/shared/models/breadcrumb.model';
import {Button}                                      from '@src/app/shared/models/button.model';
import { ApiService } from '@src/app/shared/services/api.service';
import {query}                                              from 'js-query-builder';

@Component({
  selector: 'app-custom-products',
  templateUrl: './custom-products.component.html',
  styleUrls: ['./custom-products.component.css']
})
export class CustomProductsComponent implements OnInit, AfterViewInit
{
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    //'item_type_id' , 'width', 'height'
    // public displayedColumns: string[] = ['name', 'status_id', 'direct_employee_cost', 'sub_contractor_cost', 'material_cost', 'sell_price', 'manufacturer_id', 'actions'];
    public displayedColumns: string[] = ['name', 'status_id', 'author', 'actions'];
    public products: MatTableDataSource<any> = new MatTableDataSource();
    public totalRows: number = 0;
    public pageSize: number = 50;
    public currentPage: number = 0;
    public pageSizeOptions: number[] = [5, 10, 25, 100];
    public productMeasures = [];

    public productsFilterForm: UntypedFormGroup = new UntypedFormGroup({
		name: new UntypedFormControl(null),
		status_id: new UntypedFormControl(null),
		measure_id: new UntypedFormControl(null),
	});

    get f() {
		return this.productsFilterForm.controls;
	}

    public breadcrumbs: Breadcrumb[] = [
        {
            title: 'Home',
            url: '/app/',
            active: false
        },
        {
            title: 'Products',
            active: true
        },
    ];
    public buttons: Button[] = [
        {
            type: 'link',
            material: 'flat',
            color: 'primary',
            title: 'Add Product',
            url: 'create',
            permission: 'add_products',
        }
    ];

    public constructor(
        private globalService: GlobalService,
        private apiService: ApiService,
        public router: Router,
        public dialog: MatDialog,
    )
    {
        this.apiService.get('products/measures?fields[product_measures]=id,unit,symbol').subscribe(
			result => this.productMeasures = result.data,
			error => this.apiService.handleError(error)
		);
    }

    public ngOnInit(): void
    {
        this.globalService.pageTitle = 'Products';
        this.loadProducts();
    }

    public ngAfterViewInit()
    {
        this.products.paginator = this.paginator;
    }

    public loadProducts()
    {
        const url = query('products')
        .when(this.f.name.value != null, q => q.filter('name', this.f.name.value))
        .when(this.f.status_id.value != null, q => q.filter('status_id', this.f.status_id.value))
        .when(this.f.measure_id.value != null, q => q.filter('measure_id', this.f.measure_id.value))
        .filter('author_id-gt', 0)
        .include('status', 'author').build();
        this.apiService.queryList(url, this.currentPage + 1, this.pageSize).subscribe(
            response => {
                this.products = response.data;
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = response.meta.total;
            },
            error => this.apiService.handleError(error)
        );
    }

    public pageChanged(event: PageEvent)
    {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;

        this.loadProducts();
    }

    public onArchive(id)
    {
        this.apiService.delete('products', id).subscribe(() => {
            this.loadProducts();
        }, error => this.apiService.handleError(error));
    }

    public clearFilters(): void {
		this.productsFilterForm.reset();
		this.loadProducts();
	}
}
