import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS}                       from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule}                             from '@angular/forms';
import {MAT_DATE_LOCALE}                        from '@angular/material/core';
import {MatListModule}                          from '@angular/material/list';
import {MatSidenavModule}                       from '@angular/material/sidenav';
import {MatSnackBarModule}                      from '@angular/material/snack-bar';
import {BrowserModule}                          from '@angular/platform-browser';
import {BrowserAnimationsModule}                from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {JwtModule}                              from '@auth0/angular-jwt';
import * as Sentry                              from '@sentry/angular-ivy';
import {ProductsModule}                         from '@src/app/admin/products/products.module';
import {AppRoutingModule}                       from '@src/app/app-routing.module';
import {AppComponent}                           from '@src/app/app.component';
import {CoreModule}      from '@src/app/core/core.module';
import {AuthInterceptor} from '@src/app/core/interceptors/auth-interceptor';
import {AdminModule}     from '@src/app/layout/admin/admin.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        AdminModule,
        FormsModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        ProductsModule,
    ],
    declarations: [AppComponent],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: LOCALE_ID,
            useValue: 'en-GB'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'GBP'
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule
{
}
