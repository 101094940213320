import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductsIndexComponent} from '@src/app/admin/products/components/index/products-index.component';
import { ProductsFormComponent } from './components/form/products-form.component';

const routes: Routes = [
    {
        path: '',
        component: ProductsIndexComponent,
        data: {
            permission: 'view_products'
        }
    },
    {
        path: 'edit/:id',
        component: ProductsFormComponent,
        data: {
            permission: 'add_products'
        }
    },
    {
        path: 'create',
        component: ProductsFormComponent,
        data: {
            permission: 'add_products'
        }
    },
];

@NgModule({
    providers: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductsRoutingModule {
}
