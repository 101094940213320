import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  public token: string;
  public email: string;
  public passwordForm: UntypedFormGroup;
  public error: any;
  public submitted = false;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private fb: UntypedFormBuilder,
      private authService: AuthService,
      private snackBar: MatSnackBar,
  ) {
      if (this.authService.authenticated) {
          this.router.navigate(['/app']).then(r => r);
      }
  }

  get f() {
      return this.passwordForm.controls;
  }

  public ngOnInit(): void {
      this.passwordForm = this.fb.group({
          password: [null, Validators.required],
          password_confirmation: [null, Validators.required],
      });
  }

  public onSubmit(): void {
      this.submitted = true;

      if (this.passwordForm.invalid) {
          return;
      }

      this.passwordForm.disable();

      this.authService.createPassword(this.activatedRoute.snapshot.queryParamMap.get('url'), this.passwordForm.value).subscribe(
          () => {
              this.snackBar.open('Password successfully created. You can now login.', 'Ok', {duration: 3000});
              this.router.navigateByUrl('/login').then(r => r);
          },
          (response: HttpErrorResponse) => {
              this.passwordForm.enable();
              this.snackBar.open(response?.error?.message, 'Ok', {duration: 5000});
          }
      );
  }
}
