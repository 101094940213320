import {Component} from '@angular/core';
import { Location } from '@angular/common';
@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
})
export class NotFoundComponent
{
    constructor(private location: Location)
    {
    }
    public goBack(): void
    {
      this.location.back();
    }
}
