<mat-accordion style="margin-bottom: 10px;">
    <mat-expansion-panel class="my-3">
        <mat-expansion-panel-header style="padding: 0 20px 0 16px;">
            <mat-panel-title>Financial Details</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="preInstallPricing">
            <h4 class="mb-3">Survey</h4>

            <div class="row">
                <div class="col-12 col-xl-4">
                    <h3 class="pin-price">{{ preInstallPricing?.engineer_cost ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Operative Cost</p>
                </div>

                <div class="col-12 col-xl-4">
                    <mat-divider [vertical]="true"></mat-divider>

                    <h3 class="pin-price">{{ preInstallPricing?.material_cost ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Material Cost</p>

                    <mat-divider [vertical]="true"></mat-divider>
                </div>

                <div class="col-12 col-xl-4">
                    <h3 class="pin-price">{{ preInstallPricing?.sell_price ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Sell Price</p>
                </div>
            </div>

            <hr />
        </ng-container>

        <ng-container *ngIf="installPricing">
            <h4 class="mb-3">Install</h4>
            <div class="row">
                <div class="col-12 col-xl-4">
                    <h3 class="pin-price">{{ installPricing?.engineer_cost ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Operative Cost</p>
                </div>

                <div class="col-12 col-xl-4">
                    <mat-divider [vertical]="true"></mat-divider>

                    <h3 class="pin-price">{{ installPricing?.material_cost ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Material Cost</p>

                    <mat-divider [vertical]="true"></mat-divider>
                </div>

                <div class="col-12 col-xl-4">
                    <h3 class="pin-price">{{ installPricing?.sell_price ?? 0 | currency }}</h3>
                    <p class="pin-price-title text-uppercase mat-card-subtitle mat-card-title">Sell Price</p>
                </div>
            </div>
        </ng-container>

        <p *ngIf="priceModifier" class="text-center mt-2 mb-0">Site has a {{ priceModifier }}% modifier applied.</p>
    </mat-expansion-panel>
</mat-accordion>
