import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@src/app/shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PaymentService extends BaseService  {

	public constructor(
		private http: HttpClient,
	) {
		super();
	}

	public checkout(data): Observable<any> {
		return this.http.post(`${this.apiUrl}/payments`, data).pipe();
	}

	public getProducts(): Observable<any> {
		return this.http.get(`${this.apiUrl}/payments/products?frequency_type=one-off&include_prices`).pipe();
	}

	public getCustomerPortal(): Observable<any> {
		return this.http.get(`${this.apiUrl}/payments/billing-portal`, {
			params: {
				frontend_url: window.location.href
			}
		}).pipe();
	}

	public checkSession(session_id){
		return this.http.get(`${this.apiUrl}/payments`, {
			params: {
				session_id: session_id
			}
		}).pipe();
	}

}
