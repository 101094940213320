import {HttpClient}      from '@angular/common/http';
import {Injectable}      from '@angular/core';
import {BuildingService} from '@src/app/admin/buildings/services/building.service';
import {FloorService}    from '@src/app/admin/floors/services/floor.service';
import {SiteService}     from '@src/app/admin/sites/services/site.service';
import {environment}     from '@src/environments/environment';
import {Observable}      from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DrawingService
{
    public baseUrl: string;
    public drawingId: number;

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
        private buildingService: BuildingService,
        private floorService: FloorService,
    )
    {
        this.baseUrl = environment.apiUrl;
    }

    CreateDrawing(data, file: File): Observable<any>
    {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('floor_id', data.floor_id);
        formData.append('plan_file', file, file.name);
        if(data.survey_completed_at){
            formData.append('survey_completed_at', data.survey_completed_at);
        }

        if (data.start_at) {
            formData.append('start_at', data.start_at);
        }

        return this.http.post(`${this.baseUrl}/drawings`, formData).pipe();
    }

    EditDrawing(data, id): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/drawings/${id}`, data).pipe();
    }

    DeleteDrawing(id): Observable<any>
    {
        return this.http.delete(`${this.baseUrl}/drawings/${id}`).pipe();
    }

    GetDrawingData(id)
    {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.floorService.GetFloorId())
            .drawings.find(c => c.id === this.SetDrawingId(id));
    }

    SetDrawingId(id)
    {
        localStorage.setItem('drawingId', JSON.stringify(id));
        this.drawingId = id;
        return this.drawingId;
    }

    GetDrawingId()
    {
        const drawingId = localStorage.getItem('drawingId');

        if (drawingId) {
            try {
                this.drawingId = JSON.parse(drawingId);
            } catch (e) {
                localStorage.removeItem('drawingId');
            }

            return this.drawingId;
        }
    }

    GetDrawingsReport(id): Observable<any>
    {
        return this.http.get(`${this.baseUrl}/drawings/${id}/report/pins-by-status`).pipe();
    }

    ErrorHandler(response)
    {
        console.log(response);
    }
}
