import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@src/app/shared/services/base.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CreditsService extends BaseService  {

	constructor(
		private http: HttpClient
		) {
		super();
	}

	public getTransactionTypes(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/credits/transaction-types`).pipe();
    }

	public getTransactionOrigins(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/credits/transaction-origins`).pipe();
    }

	public getCreditsBalance(): Observable<any> {
		return this.http.get(`${this.apiUrl}/credits/balance`).pipe();
	}

}
