import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MatDialog}                                   from '@angular/material/dialog';
import {MatPaginator, PageEvent}                     from '@angular/material/paginator';
import {MatTableDataSource}                          from '@angular/material/table';
import {Router}                                      from '@angular/router';
import {GlobalService}                               from '@src/app/core/services/global.service';
import {Breadcrumb}                                  from '@src/app/shared/models/breadcrumb.model';
import {Button}                                      from '@src/app/shared/models/button.model';
import { ApiService } from '@src/app/shared/services/api.service';
import {query}                                              from 'js-query-builder';

@Component({
    selector: 'app-products',
    templateUrl: './products-index.component.html',

})
export class ProductsIndexComponent implements OnInit
{
    public breadcrumbs: Breadcrumb[] = [
        {
            title: 'Home',
            url: '/app/',
            active: false
        },
        {
            title: 'Products',
            active: true
        },
    ];
    public buttons: Button[] = [
        {
            type: 'link',
            material: 'flat',
            color: 'primary',
            title: 'Add Product',
            url: 'create',
            permission: 'add_products',
        }
    ];

    public constructor(
        private globalService: GlobalService,
        public router: Router,
        public dialog: MatDialog,
    )
    {
    }

    public ngOnInit(): void
    {
        this.globalService.pageTitle = 'Products';
    }
}
