import {Component, Input, OnInit} from '@angular/core';
import {User}                     from '@src/app/admin/users/models/user.model';

@Component({
    selector: 'assignees-panel',
    templateUrl: './assignees-panel.component.html',
})
export class AssingeesPanelComponent implements OnInit
{
    @Input() public assignees: User[];

    public constructor()
    {
    }

    public ngOnInit(): void
    {
    }

    public sendEmail(assignee: User): void
    {
        console.log('test the email');
        window.location.href = `mailto:${assignee.email}`;
    }
}
