import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Breadcrumb } from '@src/app/shared/models/breadcrumb.model';
import { Button } from '@src/app/shared/models/button.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PricingModalComponent } from '../pricing-modal/pricing-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@src/app/shared/services/api.service';
import {query}                                              from 'js-query-builder';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-create',
	templateUrl: './products-form.component.html',
	styleUrls: ['./products-form.component.css']
})
export class ProductsFormComponent implements OnInit {
	public displayedColumns: string[] = ['manufacturer', 'operative_cost', 'subcontractor_cost', 'material_cost', 'sell_price', 'start_at', 'created_by', 'created_at', 'actions'];
    public manufacturerPrices: MatTableDataSource<any> = new MatTableDataSource();
	public create: boolean = true;
	public productMeasures: any[] = [];
	public product: any;

	public productForm = new UntypedFormGroup({
		name: new UntypedFormControl(null, Validators.required),
		status_id: new UntypedFormControl(1, Validators.required),
		measure_id: new UntypedFormControl(null, Validators.required),
		display_measure: new UntypedFormControl(null)
	});

	public breadcrumbs: Breadcrumb[] = [
		{
			title: 'Home',
			url: '/app/',
			active: false
		},
		{
			title: 'Products',
			active: false,
			url: '/app/products/',
		},
		{
			title: this.create ? 'Add Product' : 'Edit Product',
			active: true,
		},
	];

	public buttons: Button[] = [
		{
			type: 'button',
			material: 'flat',
			color: 'primary',
			title: 'Save Product',
			permission: 'add_products',
			callback: () => {
				this.onSubmit();
			}
		}
	];

	constructor(
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
        private snackBar: MatSnackBar,
	) { }

	ngOnInit() {
		this.apiService.get('products/measures?fields[product_measures]=id,unit,symbol').subscribe(
			result => this.productMeasures = result.data,
			error => this.apiService.handleError(error)
		);

		this.activatedRoute.params.subscribe(params => {
			if(params['id']){
				this.create = false;
				this.getProductData(params['id']);
			}
		});
	}

	public onSubmit(){
		if (this.productForm.valid) {
			if(this.product){
				this.apiService.update('products', this.product.id, this.productForm.value).subscribe(
					result => {
						this.router.navigateByUrl(`/app/products`);
					},
					error => this.apiService.handleError(error)
				)
			} else {
				this.apiService.create('products', this.productForm.value).subscribe(
					result => {
						this.router.navigateByUrl(`/app/products/edit/${result.data.id}`);
					},
					error => this.apiService.handleError(error)
				);
			}
		}
	}

	public pricingModal(price = null) {
		const productsDialog: MatDialogRef<PricingModalComponent> = this.dialog.open(PricingModalComponent, {
			width: '65%',
			disableClose: true,
			data: {
				price: price ?? null,
				product_id: this.product.id
			}
		});

		productsDialog.afterClosed().subscribe((data): void => {
			if (data) {
				//TODO: Add the ability to insert the manufacturer pricing into the table
				this.getProductData(this.product.id);
			}
		});
	}

	public onArchive(id){
		this.apiService.delete(`products/manufacturer`, id).subscribe(
			() => this.getProductData(this.product.id),
			error => {
                this.apiService.handleError(error.message);
                this.snackBar.open('You cannot delete a manufacturer product link that is in use on existing sites.', 'Ok.', {duration: 4000});
            }
		)
	}

	public getProductData(id){
		const url = query(`products/${id}}`).build();
		this.apiService.get(url).subscribe(
			result => {
				this.product = result.data;
				this.productForm = new UntypedFormGroup({
					name: new UntypedFormControl(this.product.name, Validators.required),
					status_id: new UntypedFormControl(this.product.status.id, Validators.required),
					measure_id: new UntypedFormControl(this.product.measure.id, Validators.required),
					display_measure: new UntypedFormControl(this.product.display_measure),
				});

				this.manufacturerPrices = this.product.default_prices;
			},
			error => this.apiService.handleError(error)
		);
	}
}
