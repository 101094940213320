import {Injectable}      from '@angular/core';
import {AuthService}     from '@src/app/core/services/auth.service';
import {BaseService}     from '@src/app/shared/services/base.service';
import {environment}     from '@src/environments/environment';
import Pusher, {Channel} from 'pusher-js';

@Injectable({
    providedIn: 'root'
})
export class PusherService extends BaseService
{
    public pusher: Pusher;

    constructor(
        public authService: AuthService
    )
    {
        super();

        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
            channelAuthorization: {
                transport: 'ajax',
                endpoint: `${environment.apiUrl}/broadcasting/auth`,
                headers: {
                  'Authorization': `Bearer ${this.authService.accessToken}`,
                  'X-Tenant': `${this.authService.tenant.id}`
                }
              }
        });
    }

    public listen(channel: string, type: 'private'|'public' = 'private'): Channel
    {
        const channelName: string = type === 'private' ? `private-${channel}` : channel;
        return this.pusher.subscribe(channelName);
    }

    public unsubscribe(channel: string, type: 'private'|'public' = 'private'): void
    {
        const channelName: string = type === 'private' ? `private-${channel}` : channel;
        this.pusher.unsubscribe(channelName);
    }
}
