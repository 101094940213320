import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'financials-panel',
    templateUrl: './financials-panel.component.html',
})
export class FinancialsPanelComponent implements OnInit
{
    @Input() public preInstallPricing: any = null;
    @Input() public installPricing: any = null;
    @Input() public priceModifier: any = null;

    public constructor()
    {
    }

    public ngOnInit(): void
    {
    }
}
