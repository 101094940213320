import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
    HttpErrorResponse, HttpClient, HttpHeaders
} from '@angular/common/http';
import { Observable, throwError }   from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import { Router }                   from '@angular/router';
import { AuthService } from '../services/auth.service';
import {environment} from '@src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private authService: AuthService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('X-Skip')) {
            return next.handle(request);
        }

        let authRequest = request;
        if (this.authService.accessToken && this.authService.tenant?.id) {
            authRequest = request.clone({
                setHeaders: {   // use setHeaders instead of HttpHeaders
                    'Authorization': `Bearer ${this.authService.accessToken}`,
                    'X-Tenant': `${this.authService.tenant.id}`,
                    'X-Client-Ident': `${environment.webEnum}`
                }
            });
        }

        return next.handle(authRequest).pipe(catchError(err => {
            if (err.status === 401) {
                this.authService.deauthenticateUser();
            }
            return throwError(err);
        }));

        // return this.authService.check().pipe(
        //     switchMap((authenticated: boolean) => {
        //         if (!authenticated) {
        //             console.info('Not authenticated, redirecting to login.');

        //             this.router.navigate(['/login']);
        //         }

        //         // if (this.tokenExpired(this.authService.accessToken) && !this.tokenExpired(this.authService.refreshToken)) {
        //         //     console.info('Token expired, refreshing.');
        //         //
        //         //     const headerDict = {
        //         //         'Authorization': `Bearer ${this.authService.refreshToken}`,
        //         //         'X-Tenant': `${this.authService.tenant.id}`,
        //         //         'X-Skip': 'true'
        //         //     }
        //         //
        //         //     const requestOptions = {
        //         //         headers: new HttpHeaders(headerDict),
        //         //     };
        //         //
        //         //     this.httpClient.get(`${environment.apiUrl}/refresh`, requestOptions).subscribe(
        //         //         (response: any) => {
        //         //             console.log('Token refreshed successfully.');
        //         //             this.authService.authenticateUser(response);
        //         //         },
        //         //         (error) => {
        //         //             console.error(error);
        //         //             this.authService.logout();
        //         //         }
        //         //     );
        //         // }

        //         let authRequest = request;
        //         if (this.authService.accessToken && this.authService.tenant?.id) {
        //             authRequest = request.clone({
        //                 setHeaders: {   // use setHeaders instead of HttpHeaders
        //                     'Authorization': `Bearer ${this.authService.accessToken}`,
        //                     'X-Tenant': `${this.authService.tenant.id}`,
        //                     'X-Client-Ident': `${environment.webEnum}`
        //                 }
        //             });
        //         }

        //         return next.handle(authRequest);
        //     }),
        //     catchError((error: HttpErrorResponse) => {
        //         if (error.status === 401) {
        //             console.log('401 error, logging out.');

        //             // this.authService.logout();
        //             // location.reload();
        //         }
        //         return throwError(error);
        //     })
        // );
    }

    private tokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
}
