<form [formGroup]="productForm">
	<app-page-header [title]="create ? 'Add Product' : 'Edit Product'" [breadcrumbs]="breadcrumbs" [buttons]="buttons"></app-page-header>

	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<mat-card class="mat-card mat-focus-indicator">
					<mat-card-title>
						<h3 style="display: inline-block;">Product Details</h3>
						<mat-icon style="float: right;" matTooltip="You only need to add a thickness / fixed dimension for products like ablative batt, 50mm thick but its only the width and height that matters for calcuating price, not thickness.">info</mat-icon>
					</mat-card-title>

					<mat-card-content>
						<div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input matInput type="text" formControlName="name" required />

                                    <ng-container *ngIf="productForm.controls.name.errors && (productForm.controls.name.dirty || productForm.controls.name.touched)">
                                        <mat-error *ngIf="productForm.controls.name.errors.required">Please enter a Name.</mat-error>
                                    </ng-container>
                                </mat-form-field>
							</div>

							<div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-label>Thickness / Fixed Dimension</mat-label>
                                    <input matInput type="text" formControlName="display_measure" placeholder="Please put the unit of measure after the value, ie. 60mm"/>
                                </mat-form-field>
							</div>

							<div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-label>Status</mat-label>
									<mat-select formControlName="status_id" required>
                                        <mat-option [value]="1">Active</mat-option>
										<mat-option [value]="2">In Active</mat-option>
                                    </mat-select>

                                    <ng-container *ngIf="productForm.controls.status_id.errors && (productForm.controls.status_id.dirty || productForm.controls.status_id.touched)">
                                        <mat-error *ngIf="productForm.controls.status_id.errors.required">Please select a status.</mat-error>
                                    </ng-container>
                                </mat-form-field>
							</div>

							<div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-label>Unit of measure</mat-label>
                                    <mat-select formControlName="measure_id" required>
                                        <mat-option *ngFor="let measure of productMeasures" [value]="measure.id">{{ measure.unit | titlecase }} {{ measure.symbol ? "(" + measure.symbol + ")" : '' }}</mat-option>
                                    </mat-select>

                                    <ng-container *ngIf="productForm.controls.measure_id.errors && (productForm.controls.measure_id.dirty || productForm.controls.measure_id.touched)">
                                        <mat-error *ngIf="productForm.controls.measure_id.errors.required">Please enter a unit of measure.</mat-error>
                                    </ng-container>
                                </mat-form-field>
							</div>
						</div>

					</mat-card-content>
				</mat-card>
			</div>
		</div>

		<div class="row" *ngIf="!create">
			<div class="col-12">
				<mat-card class="mat-card mat-focus-indicator">
					<mat-card-title>
						<h3 class="d-inline-block">Manufacturers and Pricing</h3>
						<button class="float-end" mat-stroked-button color="primary" (click)="pricingModal()">Add Manufacturer</button>
					</mat-card-title>

					<mat-card-subtitle *ngIf="product?.measure">
						All prices shown are per {{ product.measure.unit | titlecase }} {{ product.measure.symbol ? "(" + product.measure.symbol + ")" : '' }}
					</mat-card-subtitle>

					<mat-card-content>
						<table mat-table [dataSource]="manufacturerPrices">
							<ng-container matColumnDef="manufacturer">
								<th mat-header-cell *matHeaderCellDef>Manufacturer</th>
								<td mat-cell *matCellDef="let price">
									{{price?.manufacturer?.name}}
								</td>
							</ng-container>

							<ng-container matColumnDef="operative_cost">
								<th mat-header-cell *matHeaderCellDef>Operative Cost</th>
								<td mat-cell *matCellDef="let price">{{price.operative_cost | currency}}</td>
							</ng-container>

							<ng-container matColumnDef="subcontractor_cost">
								<th mat-header-cell *matHeaderCellDef>Subcontractor Cost</th>
								<td mat-cell *matCellDef="let price">{{price.subcontractor_cost | currency}}</td>
							</ng-container>

							<ng-container matColumnDef="material_cost">
								<th mat-header-cell *matHeaderCellDef>Material Cost</th>
								<td mat-cell *matCellDef="let price">{{price.material_cost | currency}}</td>
							</ng-container>

							<ng-container matColumnDef="sell_price">
								<th mat-header-cell *matHeaderCellDef>Sell Price</th>
								<td mat-cell *matCellDef="let price">
									<span class="mat-badge">{{price.sell_price | currency}}</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="start_at">
								<th mat-header-cell *matHeaderCellDef>Price Effective From</th>
								<td mat-cell *matCellDef="let price">
									<span class="mat-badge">{{price.start_at | date: 'dd/MM/yyyy'}}</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="created_by">
								<th mat-header-cell *matHeaderCellDef>Created By</th>
								<td mat-cell *matCellDef="let price">{{price.author?.name}}</td>
							</ng-container>

							<ng-container matColumnDef="created_at">
								<th mat-header-cell *matHeaderCellDef> Created At</th>
								<td mat-cell *matCellDef="let price">{{price.created_at | date: 'd/MM/YYYY h:mma'}}</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<th mat-header-cell *matHeaderCellDef>Actions</th>
								<td mat-cell *matCellDef="let price; let index = index">
									<button mat-icon-button (click)="pricingModal(price)"
									*appCan="'edit_products'">Edit</button>

									<button mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"
									>
										<mat-icon>more_vert</mat-icon>
									</button>

									<mat-menu #menu="matMenu">
										<ng-container *appCan="'delete_products'">
											<!-- <mat-divider></mat-divider> -->
											<a [swal]="{titleText: 'Are you sure you want to archive this manufacturer from this product?', text: 'You can\'t undo this action.', icon: 'warning'}"
												(confirm)="onArchive(price.manufacturer_product_id)" mat-menu-item>
												Archive
											</a>
										</ng-container>
									</mat-menu>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</form>
