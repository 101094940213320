import {HttpClient}  from '@angular/common/http';
import {Injectable}  from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Site}        from '@src/app/admin/sites/models/site.model';
import { GlobalService } from '@src/app/core/services/global.service';
import { ApiService } from '@src/app/shared/services/api.service';
import {environment} from '@src/environments/environment';
import {Observable}  from 'rxjs';
import Swal          from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
    providedIn: 'root'
})
export class SiteService
{
    public baseUrl: string;
    public site: Site;
    public siteData: Site[]; // TODO: Refactor out
    public siteId: number; // TODO: Refactor out

    public constructor(
        private http: HttpClient,
        private globalService: GlobalService,
        private snackbar: MatSnackBar
        )
    {
        this.baseUrl = environment.apiUrl;
    }

    public EditSite(id, data): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/sites/${id}`, data).pipe();
    }

    public transitionStatus(id, data): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/sites/${id}/transition-status`, data).pipe();
    }

    public transitionSiteStatus(siteStatus: any) {
        return Swal.fire({
            title: `Are you sure want to update the site status to ${siteStatus.title}?`,
            icon: 'warning',
            iconColor: '#ff9030',
            background: '#424242',
            customClass: {
                title: 'sweetAlertTitle'
            },
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                let site = this.globalService.site;
                site.status = siteStatus;
                await this.transitionStatus(site.id, {
                    name: site.name,
                    customer_id: site.customer.id,
                    status_id: site.status.id,
                    address_1: site.address_1,
                    postcode: site.postcode
                }).toPromise().then(res => {
                    this.globalService.site = site;
                }).catch(error => {
                    this.snackbar.open('An error has occurred please try again!', 'Ok', { duration: 5000 });
                    this.ErrorHandler(error)
                });
            }
        });
    }

    public DeleteSite(id): Observable<any>
    {
        return this.http.delete(`${this.baseUrl}/sites/${id}`).pipe();
    }

    public SetSitesData(value)
    {
        localStorage.setItem('siteData', JSON.stringify(value));
        this.siteData = value;
    }

    public getSitesReport(id, data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/sites/${id}/report`, data);
    }

    public getPinHistoriesReport(id): Observable<Blob>
    {
        return this.http.get(`${this.baseUrl}/sites/${id}/report/pins-histories`, {responseType: 'blob'});
    }

    public GetSitesData()
    {
        const siteData = localStorage.getItem('siteData');

        if (siteData) {
            try {
                this.siteData = JSON.parse(siteData);
            } catch (e) {
                localStorage.removeItem('siteData');
            }

            return this.siteData;
        }
    }

    public GetSiteId()
    {
        const siteId = localStorage.getItem('siteId');

        if (siteId) {
            try {
                this.siteId = JSON.parse(siteId);
            } catch (e) {
                localStorage.removeItem('siteId');
            }

            return +this.siteId;
        }
    }

    public CreateJob(data): Observable<any>
    {
        return this.http.post(`${this.baseUrl}/jobs/create`, data).pipe();
    }

    public GetSitesReport(id): Observable<any>
    {
        return this.http.get(`${this.baseUrl}/sites/${id}/report/pins-by-status`).pipe();
    }

    public ErrorHandler(response)
    {
        console.error(response);
    }
}
