import {CommonModule}           from '@angular/common';
import {NgModule}               from '@angular/core';
import {BrowserModule}          from '@angular/platform-browser';
import {ProductsManageComponent}  from '@src/app/admin/products/components/form.old/products-form-old.component';
import {ProductsIndexComponent} from '@src/app/admin/products/components/index/products-index.component';
import {ProductsRoutingModule}  from '@src/app/admin/products/products-routing.module';
import {AdminModule}            from '@src/app/layout/admin/admin.module';
import { ProductsFormComponent } from './components/form/products-form.component';
import { PricingModalComponent } from './components/pricing-modal/pricing-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DefaultProductsComponent } from './components/index/default-products/default-products.component';
import { CustomProductsComponent } from './components/index/custom-products/custom-products.component';

@NgModule({
    imports: [
        ProductsRoutingModule,
        AdminModule,
        CommonModule,
        MatDatepickerModule
    ],
    declarations: [
        ProductsIndexComponent,
        DefaultProductsComponent,
        CustomProductsComponent,
        ProductsManageComponent,
        ProductsFormComponent,
        PricingModalComponent
    ],
    exports: [
        ProductsIndexComponent,
        DefaultProductsComponent,
        CustomProductsComponent,
        ProductsManageComponent,
        ProductsFormComponent,
        PricingModalComponent
    ],
})
export class ProductsModule
{
}
