import {Component} from '@angular/core';
import packageJson from '../../../../../../package.json'

@Component({
    selector: 'app-admin-footer',
    templateUrl: './admin-footer.component.html',
    styleUrls: ['./admin-footer.component.scss']
})
export class AdminFooterComponent {
    public currentYear: number = new Date().getFullYear();
    public version: string;

    public constructor() {
        this.version = packageJson.version;
    }
}
