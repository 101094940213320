<h1 mat-dialog-title style="width: 100%;">Credit bundles</h1>

<div mat-dialog-content>
	<div class="row">
		<div class="col-12 col-md-4" *ngFor="let product of products">
			<button mat-stroked-button type="button" class="report-btn" (click)="selectPackage(product)">
				<h1><strong>{{product.metadata.credit_amount}}</strong></h1>
				<h3>{{product.prices[0].unit_amount / 100 | currency}}</h3>
				<div *ngIf="product?.metadata?.discount" class="report-btn-percent-off">{{product?.metadata?.discount}} Off</div>
			</button>
		</div>
	</div>
</div>

<div mat-dialog-actions>
	<div class="col-12">
		<button mat-stroked-button (click)="closeDialog()">Cancel</button>
		<button class="float-end" mat-raised-button color="primary" (click)="checkout()" [disabled]="!selectedProduct">Buy {{selectedProduct?.metadata?.credit_amount}} credits</button>
	</div>
</div>