import {Pipe, PipeTransform} from '@angular/core';
import {PermissionsService}  from '@src/app/core/services/permissions.service';

@Pipe({ name: 'hasPermission' })
export class HasPermissionPipe implements PipeTransform
{
    constructor(
        private permissionsService: PermissionsService
    ) {
    }

    public transform(permission: string): boolean
    {
        return this.permissionsService.can(permission);
    }
}
