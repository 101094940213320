<mat-accordion style="margin-bottom: 10px;">
    <mat-expansion-panel class="mb-3" [expanded]="true">
        <mat-expansion-panel-header style="padding: 0 20px 0 16px;">
            <mat-panel-title>Assignees</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list role="list">
            <ng-container *ngFor="let assignee of assignees">
                <mat-list-item role="listitem">
                    <h3 mat-line>{{ assignee?.name }}</h3>

                    <p mat-line>
                      <span>{{ assignee?.type?.title }}</span>
                    </p>
                </mat-list-item>

                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </mat-expansion-panel>
</mat-accordion>
